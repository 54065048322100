import React, { useState } from 'react';
import Landscape from './assets/jc-gellidon-XmYSlYrupL8-unsplash.jpg';
import { Link, useLocation } from 'react-router-dom';
import '../style/Home.css';
import Game1 from './assets/LeGuessTheTeam.jpg';
import Game2 from './assets/immagine top 75.jpg';
import Game3 from './assets/LeGuessThePlayer.jpg';
import Game4 from './assets/Nicknames.jpg';
import Overlay from './Helpers/Overlay';
import NewsTicker from './Helpers/NewsTicker';

function Home() {
  const location = useLocation();
  
  const isHome = location.pathname === '/';

  const [isToggleOpen, setIsToggleOpen] = useState(false);

  const openGames = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  const games = [
    {
        name: 'LeGuess the Team',
        img: Game1,
        domain: 'leguesstheteam'
    },
    {
        name: 'Top 75 of All Time',
        img: Game2,
        domain: 'topplayers'
    },
    {
        name: 'LeGuess the Player',
        img: Game3,
        domain: 'leguesstheplayer'
    },
    {
        name: 'Nicknames',
        img: Game4,
        domain: 'nicknames'
    },
  ]

  return (
    <div 
      className={`w-screen flex justify-center items-center flex-grow ${isHome ? 'bg-cover bg-center' : 'bg-black'}`}
      style={isHome ? { 
        backgroundImage: `url(${Landscape})`, 
        backgroundSize: 'cover',
        height: '65vh',
        width: '99vw'
      } : {}}
    >
      {/* <NewsTicker></NewsTicker> */}
      {isHome && !isToggleOpen && (
        <div className="relative inline-flex group">
          <div
            className="absolute transition-all duration-1000 opacity-70 -inset-2 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-2 group-hover:duration-200 animate-pulse-custom"
          ></div>
          <a
            href="#"
            title="Get quote now"
            className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 animate-pulse"
            role="button"
            onClick={openGames}
          >
            PLAY
          </a>
        </div>
      )}
      {isHome && isToggleOpen && (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-7 p-4">
          {games.map((game, index) => (
            <Link to={`/${game.domain}`}>
            <div key={index} className="cursor-pointer relative group transform transition-transform duration-300 hover:scale-105">
              <div
                className="absolute transition-all duration-1000 opacity-70 -inset-2 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-2 group-hover:duration-200"
              ></div>
              <div className="max-w-[200px] relative bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] p-4 rounded-lg shadow-lg z-10">
                <img
                  src={game.img}
                  alt={game.name}
                  className="w-full h-auto mb-4 rounded-lg"
                  style={{
                    maskImage: 'radial-gradient(ellipse at center, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)',
                    WebkitMaskImage: 'radial-gradient(ellipse at center, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)',
                  }}
                />
                <p className="font-bold text-center">{game.name}</p>
              </div>
            </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default Home;
