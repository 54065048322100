import React, { useState } from 'react';
import curryTitle from './assets/Immagine_2023-09-24_162522-removebg-preview.png';
import Question from './assets/question-mark-circle-svgrepo-com.svg';
import Manual from './assets/dictionary-2-svgrepo-com.svg';
import HomeIcon from './assets/home-svgrepo-com.svg';
import { Link } from 'react-router-dom';
import Overlay from './Helpers/Overlay';
import User from './assets/user.svg';
import OffCanvas from './Helpers/OffCanvas';
import BreakingNews from './Helpers/BreakingNews';

function Navbar() {
  const [questionPopUp, setQuestionPopUp] = useState(false);
  const [dictionaryPopUp, setDictionaryPopUp] = useState(false);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false); // Stato per l'off-canvas

  const openQuestionPopUp = () => {
    setQuestionPopUp(!questionPopUp);
  }

  const openDictionaryPopUp = () => {
    setDictionaryPopUp(!dictionaryPopUp);
  }

  const toggleOffCanvas = () => {
    setIsOffCanvasOpen(!isOffCanvasOpen);
  }

  return (
    <div>
      <nav className="border-b border-b-custom-violet bg-custom-dark-violet text-white py-3 px-4 flex items-center justify-between">
        <Link to='/'>
          <div 
            style={{ 
              backgroundImage: `url(${curryTitle})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: '200px',
              height: '50px'
            }}
          >
          </div>
        </Link>
        <div className="flex items-center">
          <Link to='/' className="cursor-pointer text-sm px-4 py-2 leading-none rounded-full hover:bg-gray-700">
            <img className="w-5 h-5" src={HomeIcon} alt="Home Icon"/>
          </Link>
          <a onClick={openDictionaryPopUp} className="cursor-pointer text-sm px-4 py-2 leading-none rounded-full hover:bg-gray-700">
            <img className="w-5 h-5" src={Manual} alt="Manual Icon"/>
          </a>
          <a onClick={openQuestionPopUp} className="cursor-pointer text-sm px-4 py-2 leading-none rounded-full hover:bg-gray-700">
            <img className="w-5 h-5" src={Question} alt="Question Icon"/>
          </a>
          {/*
              <a onClick={toggleOffCanvas} className="cursor-pointer text-sm px-4 py-2 leading-none rounded-full hover:bg-gray-700">
            <img className="w-5 h-5" src={User} alt="User Icon"/>
          </a>
          */}
        </div>
      </nav>

      <BreakingNews />

      {questionPopUp && (
        <Overlay buttons={false} onExit={openQuestionPopUp} text="To consider a player in the game some conditions are required...">
        </Overlay>
      )}

      {dictionaryPopUp && (
        <Overlay buttons={true} onExit={openDictionaryPopUp} text="The game is really simple but make sure you understand...">
        </Overlay>
      )}

      <OffCanvas isOpen={isOffCanvasOpen} onClose={toggleOffCanvas} /> {/* OffCanvas */}
    </div>
  );
}

export default Navbar;
